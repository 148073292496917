<template>
  <div>
    <!-- Top Domain Details -->
    <div class="vx-row">
      <div class="vx-col w-full">
        <div class="domain-info mb-8">
          <!-- Campaign -->
          <select-filter
            id="v-step-0"
            labelname="Campaign"
            :options="campaignOptions"
            optionsName="Campaign_Name"
            :value="campaignSelected"
            @input="(newCampaign) => {campaignSelected = newCampaign}"
          ></select-filter>
          <!-- Date Picker -->
          <date-range-picker
            id="v-step-1"
            :fromDateValue="fromDate"
            :toDateValue="toDate"
            @inputFromDate="(newFromDate) => {fromDate = newFromDate}"
            @inputToDate="(newToDate) => {toDate = newToDate}"
          ></date-range-picker>
          <vs-button
            id="v-step-2"
            type="filled"
            @click="updateData()"
            class="small-btn bg-actionbutton"
          >{{ LabelConstant.buttonLabelRunQuery }}</vs-button>
        </div>
      </div>
    </div>
    <div class="vx-row">
      <!-- Call Tracking Campaign Overview -->
      <div class="vx-col w-full mb-8">
        <three-xaxis-chart
          :chartTitle="'Call Tracking Campaign Overview'"
          :chartType="'line'"
          :chartHeight="'400'"

          :seriesNameOne="'First Time Callers'"
          :seriesDataOne="callTrackingCampaignOverviewChartSeriesDataOne"
          
          :seriesNameTwo="'Successful Calls'"
          :seriesDataTwo="callTrackingCampaignOverviewChartSeriesDataTwo"

          :seriesNameThree="'Failed Calls'"
          :seriesDataThree="callTrackingCampaignOverviewChartSeriesDataThree"
          
          :optionsXaxis="callTrackingCampaignOverviewChartOptionsXaxis"
        ></three-xaxis-chart>
      </div>
      <div class="vx-col w-full">
        <!-- Call Activity -->
        <vx-card>
          <!-- Table Title -->
          <div class="table-title">
            <h2>Call Activity</h2>
            <div class="actions-button">
               <!--  <vx-tooltip color="secondary" text="CSV" class="float-left">
               <vs-button color="secondary" type="filled">
                  <img src="../assets/images/csv.svg">
               </vs-button>
               </vx-tooltip>-->
               <vx-tooltip color="actionbutton" text="EXCEL" class="float-left">
                  <vs-button type="filled" class="bg-actionbutton">
                     <download-excel
                        class="btn btn-default"
                        :data="excelData"
                        :exportFields="excelFields"
                        worksheet="Call Tracking Dashboard"
                        name="Call Tracking Dashboard.xls"
                     >
                        <img src="@/assets/images/excel.svg" />
                     </download-excel>
                  </vs-button>
                  </vx-tooltip>
            </div>
          </div>
          <div class="common-table">      
            <!--Table Select Row-->
            <div class="table-row-select">
              <vs-select label="Show Entries" v-model="perPage" class="show-select" autocomplete>
              <vs-select-item
                  :key="index"
                  :value="item.value"
                  :text="item.text"
                  v-for="(item,index) in recordList"
              />
              </vs-select>
            </div>
              <vs-table
                  :data="callActivityData"
                  search
                  :max-items="perPage"
                  ref="tableData"
                  pagination
                  id="callTrakingDashboard"
              >
                <template slot="thead">
                  <vs-th></vs-th>
                  <vs-th sort-key="Campaign_Name">Campaign</vs-th>
                  <vs-th sort-key="Incoming_Number">Incoming #</vs-th>
                  <vs-th sort-key="Duration">Duration</vs-th>
                  <vs-th sort-key="sortableDate">Date</vs-th>
                  <vs-th sort-key="IsRecorded" width="50">Recorded</vs-th>
                  <vs-th sort-key="Tags">Tags</vs-th>
                  <vs-th sort-key="Call_Rating">Rating</vs-th>
                  <vs-th sort-key="Call_Source">Source</vs-th>
                  <vs-th sort-key="Utm_Term">keyword</vs-th>
                </template>
                <template slot-scope="{data}">
                  <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                    <vs-td width="50">
                      <vs-button
                        :title="LabelConstant.buttonTitleViewCallDetails"
                        @click="openCallDetailModal(tr.Id)"                    
                        type="filled"
                        icon-pack="feather"
                        icon="icon-search"
                        class="mr-0"
                        color="primary"
                        ></vs-button>
                      </vs-td>
                    <vs-td :data="tr.Campaign_Name">{{tr.Campaign_Name}}</vs-td>
                    <vs-td :data="tr.Incoming_Number">{{tr.Incoming_Number}}</vs-td>
                    <vs-td :data="tr.Duration">{{tr.Duration_Humanized}}</vs-td>
                    <vs-td :data="tr.Date_Created">{{tr.Date_Created}}</vs-td>
                    <vs-td width="50" :data="tr.IsRecorded" class="text-center">
                      <feather-icon icon="CheckIcon" class="text-success" v-if="tr.IsRecorded"></feather-icon>
                    </vs-td>
                    <vs-td :data="tr.Tags">{{tr.Tags}}</vs-td>
                    <vs-td :data="data[indextr]" class="text-center">
                      <star-rating :rating="tr.Call_Rating" :show-rating="false" :read-only="true" active-color="#ECB940"></star-rating>
                    </vs-td>
                    <vs-td :data="tr.Call_Source">{{tr.Call_Source}}</vs-td>
                    <vs-td :data="tr.Utm_Term">{{tr.Utm_Term}}</vs-td>
                  </vs-tr>
                </template>
              </vs-table>
              <p class="table-data-entries-details" v-if="!isSearch && isTableRecordEntriesDisplay">Showing {{showingDataFrom}} to {{showingDataTo}} of {{callActivityData.length|tableRecordNumberFormatter}} entries</p>
              <p class="table-data-entries-details" v-if="isSearch && isTableRecordEntriesDisplay">Showing {{showingDataFrom}} to {{showingSearchedDataTo}} of {{totalSearchedData|tableRecordNumberFormatter}} entries (filtered from {{callActivityData.length|tableRecordNumberFormatter}} entries)</p>
              <p class="table-data-entries-details" v-if="!isTableRecordEntriesDisplay">Showing 0 to 0 of 0 entries </p>
          </div>  
        </vx-card>
      </div>
    </div>
    <!-- Call Update Modal -->
    <vs-popup class="company-details-modal" title="Call Details" v-if="isCallDetailsPopupActive" :active.sync="callUpdateActive">
      <vs-button
        @click="closeCallDetailPopup()"
        color="secondary"
        type="filled"
        class="mb-8 float-right close-popup-btn"
      >{{ LabelConstant.buttonLabelClose }}</vs-button>
      <!-- Call Information -->
      <div class="call-details mb-8">
        <div class="vx-row">          
          <div class="vx-col md:w-1/2 w-full">
              <div class="flex justify-between w-full mb-6">
                <h3>Call Information</h3>
              </div>
              <vs-table :data="callInformation">            
                <template slot-scope="{data}">                                     
                  <vs-tr>
                    <vs-th>Campaign</vs-th>
                    <vs-td>{{ data.Campaign_Name }}</vs-td>
                  </vs-tr>
                  <vs-tr>
                    <vs-th>Date of Call</vs-th>
                    <vs-td>{{ data.Date_Created }}</vs-td>
                  </vs-tr>
                  <vs-tr>
                    <vs-th>Caller Name</vs-th>
                    <vs-td>{{ data.Caller_Name }}</vs-td>
                  </vs-tr>
                  <vs-tr>
                    <vs-th>Call From</vs-th>
                    <vs-td>{{ data.Incoming_Number }}</vs-td>
                  </vs-tr>
                  <vs-tr>
                    <vs-th>Forwarded To</vs-th>
                    <vs-td>{{ data.Forwarded_To}}</vs-td>
                  </vs-tr>
                  <vs-tr>
                    <vs-th>Duration</vs-th>
                    <vs-td>{{ data.Duration_Humanized}}</vs-td>
                  </vs-tr>
                  <vs-tr>
                    <vs-th>First Time Caller</vs-th>
                    <vs-td>{{ data.IsFirstTimeCaller}}</vs-td>
                  </vs-tr> 
                  <vs-tr>
                    <vs-th>Visit Source</vs-th>
                    <vs-td>{{ data.Call_Source}}</vs-td>
                  </vs-tr> 
                  <vs-tr v-if="data.Utm_Campaign">
                    <vs-th>Source Campaign</vs-th>
                    <vs-td>{{ data.Utm_Campaign}}</vs-td>
                  </vs-tr> 
                  <vs-tr v-if="data.Utm_Term">
                    <vs-th>Source Keyword</vs-th>
                    <vs-td>{{ data.Utm_Term}}</vs-td>
                  </vs-tr>                  
                </template>                
              </vs-table>
          </div>
          <div class="vx-col md:w-1/2 mt-8 md:mt-0 w-full">
            <!-- Listen, Rate, Tag, Note -->
            <div class="call-details">
              <div class="flex justify-between w-full mb-4">
                <h3 v-if="callInformation.IsRecorded">Listen, Rate, Tag, Note</h3>
                <h3 v-if="!callInformation.IsRecorded">Rate, Tag, Note</h3>
              </div>
              <div class="rate-form">
                <div class="vx-row">
                  <div class="vx-col mt-2 mb-2 items-center w-full" v-if="callInformation.IsRecorded">
                    <av-waveform v-if="callUpdateActive"
                      :canv-width="600"
                      :audio-src="baseURL+'ws/CallTrackCallInfo/GetAudioContentDownloadVue?id='+selectedCallId+'&uid='+jwtUserID+'&cid='+jwtCustomerID+'&oid='+jwtOrgId"
                    ></av-waveform>
                  </div>
                  <div class="vx-col flex items-center w-full mb-4">
                    <span>Call Rating</span>
                    <star-rating
                      v-model="callInformation.Call_Rating"
                      :show-rating="false"
                      active-color="#ECB940"
                    ></star-rating>
                  </div>
                  <div class="vx-col flex items-center w-full mb-4">
                    <span>Tags</span>
                    <v-select
                      class="multiple-select"
                      multiple
                      :taggable="true"
                      :filterable="false"
                      :create-option="option => option"
                      label="text"
                      :closeOnSelect="false"
                      v-model="callInformation.Tags"
                      :options="tagsOptions"
                      :dir="$vs.rtl ? 'rtl' : 'ltr'"
                    />
                    <br />
                  </div>
                  <div class="vx-col flex items-center w-full">
                    <vs-textarea label="Notes" v-model="callInformation.Call_Notes" class="mb-0" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      
      <!-- Transcription -->
      <div class="call-details transcription-info mb-8" v-if="callInformation.Trans_Text != null && callInformation.Trans_Text != '' && callInformation.Trans_Text != 'No Transcription Available'">
        <div class="flex justify-between flex-wrap items-center mb-4">
          <h3>Transcription</h3>
          <vs-button
            @click="transcriptionShow = !transcriptionShow"            
            type="filled"
            icon-pack="feather"
            icon="icon-message-square"
            class="small-btn float-left"
            color="primary"
          >{{showHideTranscriptionLabel}}</vs-button>
        </div>
        <div v-if="transcriptionShow">
          <p>{{callInformation.Trans_Text}}</p>
        </div>
      </div>
      <!-- Click Path -->
      <div class="call-details mb-0" v-if="clickPath && clickPath.length > 0">
        <div class="flex justify-between mb-6">
          <h3>Click Path</h3>
        </div>
        <ul class="click-path-table">
          <li v-for="(clickPathItem, index) in clickPath" :key="index">
            <h4>Session: {{clickPathItem.Date_Created}} <span>Visitor Id: {{clickPathItem.Visitor_Id}}</span></h4>
            <vs-table :data="clickPathItem.Page_Views">
              <template slot="thead">
                <vs-th>Pages Viewed</vs-th>
                <vs-th width="170">Time Spent</vs-th>
              </template>
              <template slot-scope="{data}">
                <vs-tr :key="indextr" v-for="(tr, indextr) in data">
                  <vs-td :data="data[indextr].Page_Url">{{ data[indextr].Page_Url }}</vs-td>
                  <vs-td width="170" :data="data[indextr].Time_Spent">{{ data[indextr].Time_Spent }}</vs-td>
                </vs-tr>
              </template>
            </vs-table>
          </li>
        </ul>
      </div>
      <div class="float-right mt-10 mb-8">
        <vs-button
          @click="updateCallTrackCallInfo()"          
          type="filled"
          class="mr-4"
          color="primary"
        >{{ LabelConstant.buttonLabelUpdateInfo }}</vs-button>
        <vs-button @click="closeCallDetailPopup()" color="grey" class="grey-btn" type="border">{{ LabelConstant.buttonLabelClose }}</vs-button>
      </div>
    </vs-popup>
  </div>
</template>
<script>
import Vue from 'vue'
import VxCard from "@/components/vx-card/VxCard";
//import Cookie from "js-cookie";

/* Filter */
import SelectFilter from "../../components/filter/SelectFilter";
import DateRangePicker from "../../components/filter/DateRangePicker";
import StarRating from "vue-star-rating";
import vSelect from "vue-select";

/* chart */
import ThreeXaxisChart from '../../components/charts/ThreeXaxisChart.vue';

/* export excel */
import JsonExcel from "vue-json-excel";

import VueJwtDecode from 'vue-jwt-decode'

import AudioVisual from 'vue-audio-visual'
Vue.use(AudioVisual)

// VV Tour 
import VueTour from 'vue-tour';

require('vue-tour/dist/vue-tour.css')
Vue.use(VueTour)

export default {
  components: {
    VxCard,
    SelectFilter,
    DateRangePicker,
    StarRating,
    vSelect,
    downloadExcel: JsonExcel,
    VueJwtDecode,
    ThreeXaxisChart
  },
  data() {
    return {
      /* Campaign Filter */
      campaignSelected: {
        Id: "111111111111111111111111",
        Campaign_Name: "All"
      },
      //Domain dropdown options
      campaignOptions: [],

      //From Date
      fromDate: null,
      //To Date
      toDate: null,

      /* Call Tracking Campaign Overview Chart */
      callTrackingCampaignOverviewChartSeriesDataOne: [],
      callTrackingCampaignOverviewChartSeriesDataTwo: [],
      callTrackingCampaignOverviewChartSeriesDataThree: [],
      callTrackingCampaignOverviewChartOptionsXaxis: [],

      //Call Activity Table data
      callActivityData: [],
      perPage: 5,
      recordList: [
        { text: '5', value: 5 },
        { text: '10', value: 10 },
        { text: '25', value: 25 },
        { text: '50', value: 50 },
        { text: '100', value: 100 }
      ],

      /* Call Details Popup */
      isCallDetailsPopupActive: false,
      callUpdateActive: false,
      transcriptionShow: true,
      selectedCallId: null,
      jwtCustomerID: null,
      jwtOrgId: null,
      jwtUserID: null,
      callUrl: null,

      clickPath: [],
      callInformation: [
        {
          Id: null,
          Campaign_Name: null,
          Caller_Name: null,
          Caller_City: "",
          Caller_State: "",
          Date_Created: null,
          Duration: null,
          Duration_Humanized: null,
          Incoming_Number: null,
          To: null,
          Forwarded_To: null,
          Tags: [],
          Call_Notes: "",
          Call_Completed: null,
          IsRecorded: null,
          Recording_Id: null,
          Call_Source: null,
          IsFirstTimeCaller: false,
          Call_Rating: 5,
          Has_Visitor_Id: true,
          IsTranscribed: true,
          Trans_Text: null,
          Referrer: null,
          Utm_Campaign: null,
          Utm_Source: null,
          Utm_Medium: null,
          Utm_Term: null
        }
      ],
      tagsOptions: [],
      // excel sheet fields heading
      excelFields: {
        Campaign: "Campaign",
        "Incoming #": "Incoming",
        Duration: "Duration",
        Date: "Date",
        Recorded: "Recorded",
        Tags: "Tags",
        Rating: "Rating",
        Source: "Source",
        keyword: "keyword"
      },
      excelData: [],
      json_meta: [
        [
          {
            key: "charset",
            value: "utf-8"
          }
        ]
      ]
    };
  },
  mounted() {
    let that = this;
    //Filter Location Breakdown datatable record based on search parameter for excel
    setTimeout(() => {
      document
        .querySelector("#callTrakingDashboard .input-search")
        .addEventListener("keyup", function() {
          that.excelData = that.filterExport(true);
        });
    }, 1000);
  },
  created() {
    this.setFromDate();
    this.setToDate();

    this.getCallCampaignListForFilter();
    this.getCompletedCallList();
    this.getCallsCompletedFailedFirstTimeByDay();
  },
  methods: {
    //On clicking Update Dashboard button will update all data
    updateData() {
      this.$refs.tableData.currentx =1;
      this.getCallCampaignListForFilter();
      this.getCompletedCallList();
      this.getCallsCompletedFailedFirstTimeByDay();
    },
    //Get Campaign
    getCallCampaignListForFilter() {
      this.$vs.loading();
      this.axios
        .get("/ws/CallTrackMainDashboard/GetCallCampaignListForFilter")
        .then(response => {
          let data = response.data;
          this.campaignOptions = data;

          this.$vs.loading.close();
        })
        .catch(e => {
          this.$vs.loading.close();
          this.showError(e);
        });
    },
    //Call Tracking Campaign Overview
    getCallsCompletedFailedFirstTimeByDay() {
      let getCallsCompletedFailedFirstTimeByDayParams = {
        Campaign_Id: this.campaignSelected.Id,
        Date_End: this.toDate,
        Date_Start: this.fromDate
      };
      this.$vs.loading();
      this.axios
        .post(
          "/ws/CallTrackMainDashboard/GetCallsCompletedFailedFirstTimeByDay",
          getCallsCompletedFailedFirstTimeByDayParams
        )
        .then(response => {
          let data = response.data;
          let x = [];
          let FirstGroup = [];
          let SecondGroup = [];
          let ThirdGroup = [];

          // let scale = 1
          // let dataSize = data.length
          data.forEach(data => {
            x.push(data.x);

            FirstGroup.push(data.Calls_Successful);
            SecondGroup.push(data.Calls_Failed);
            ThirdGroup.push(data.First_Time_Callers);
          });

          this.callTrackingCampaignOverviewChartSeriesDataOne = ThirdGroup
          this.callTrackingCampaignOverviewChartSeriesDataTwo = FirstGroup
          this.callTrackingCampaignOverviewChartSeriesDataThree = SecondGroup
          this.callTrackingCampaignOverviewChartOptionsXaxis = x

          this.$vs.loading.close();
        })
        .catch(e => {
          this.$vs.loading.close();
          this.showError(e);
        });
    },
    //Call Activity
    getCompletedCallList() {
      let getCompletedCallListParams = {
        Campaign_Id: this.campaignSelected.Id,
        Date_End: this.toDate,
        Date_Start: this.fromDate
      };
      this.$vs.loading();
      this.axios
        .post(
          "/ws/CallTrackMainDashboard/GetCompletedCallList",
          getCompletedCallListParams
        )
        .then(response => {
          let data = response.data;
          this.callActivityData = data;
          this.callActivityData.map(function(manipulatedData) {
            manipulatedData.sortableDate = new Date(
              manipulatedData.Date_Created
            );
            manipulatedData.Duration = parseInt(manipulatedData.Duration);
            manipulatedData.Utm_Term = (manipulatedData.Utm_Term)?manipulatedData.Utm_Term:"";

            if (manipulatedData.Tags) {
              manipulatedData.Tags = manipulatedData.Tags.toString();
            } else {
              manipulatedData.Tags = "";
            }
            return manipulatedData;
          });
          this.callActivityData.sort(this.GetSortOrder("sortableDate", "DESC")); //Pass the attribute to be sorted on
          this.excelData = this.filterExport();
          this.$vs.loading.close();
        })
        .catch(e => {
          this.$vs.loading.close();
          this.showError(e);
        });
    },
    filterExport(search = false) {
      let data = [];
      let localListingData = !search
        ? this.callActivityData
        : this.$refs.tableData.datax;

      for (var i = 0; i < localListingData.length; i++) {
        data.push({
          Campaign: localListingData[i].Campaign_Name,
          Incoming: localListingData[i].Incoming_Number,
          Duration: localListingData[i].Duration_Humanized,
          Date: localListingData[i].Date_Created,
          Recorded: localListingData[i].IsRecorded ? "Yes" : "No",
          Tags: localListingData[i].Tags,
          Rating: localListingData[i].Call_Rating,
          Source: localListingData[i].Call_Source,
          keyword: localListingData[i].Utm_Term
        });
      }
      return data;
    },

    //get details and show call details popup
    openCallDetailModal(id){
      this.isCallDetailsPopupActive = true
      this.selectedCallId = id
      let uid = this.$route.params.id
      let jwtDecode = VueJwtDecode.decode(this.$store.state.authTokenVV[uid].token)
      this.jwtCustomerID = jwtDecode.CustomerId
      this.jwtOrgId = jwtDecode.OrgId
      this.jwtUserID = jwtDecode.UserId
      this.callUpdateActive=true
      this.getCallInfoById(id)
      this.getAvailableTags(id)
      this.getPageViewsForCaller(id)
    },
    //get call details to show call details popup
    getCallInfoById(id){
      this.$vs.loading();
      this.axios.get("/ws/CallTrackCallInfo/GetCallInfoById?id="+id).then(response => {
        let data = response.data;
        this.callInformation = data;

        this.$vs.loading.close();
      }).catch(e => {
        this.$vs.loading.close();
        this.showError(e);
      });  
    },
    //get available tags show call details popup
    getAvailableTags(id){
      this.$vs.loading();
      this.axios.get("/ws/CallTrackCallInfo/GetAvailableTags?id="+id).then(response => {
        let data = response.data;
        // this.tagsOptions = data;
        this.tagsOptions = []
        for (let i = 0; i < data.length; i++) {
          this.tagsOptions.push(data[i].text)
        }

        this.$vs.loading.close();
      }).catch(e => {
        this.$vs.loading.close();
        this.showError(e);
      });  
    },
    //get Page Views For Caller show call details popup
    getPageViewsForCaller(id){
      this.$vs.loading();
      this.axios.get("/ws/CallTrackCallInfo/GetPageViewsForCaller?id="+id).then(response => {
        let data = response.data;
        this.clickPath = data.Sessions;

        this.$vs.loading.close();
      }).catch(e => {
        this.$vs.loading.close();
        this.showError(e);
      });  
    },
    //update call track information
    updateCallTrackCallInfo(){
      let updateCallTrackCallInfoParams = {
        Id: this.selectedCallId,
        Notes: this.callInformation.Call_Notes,
        Tags: this.callInformation.Tags,
        Rating: this.callInformation.Call_Rating
      }
      this.$vs.loading();
      this.axios.post("/ws/CallTrackCallInfo/UpdateCallTrackCallInfo",updateCallTrackCallInfoParams).then(response => {
        let data = response.data;
        this.callInformation = data;
        this.$vs.notify({
          title: "Success",
          text: "Call information has been updated successfully.",
          color: "success",
          iconPack: "feather",
          icon: "icon-check",
          position: "top-right",
          time: 4000
        });
        this.$vs.loading.close();
      }).catch(e => {
        this.$vs.loading.close();
        this.showError(e);
        this.$vs.notify({
          title: "Error",
          text: e.response.data.Errors[0].Message,
          color: "danger",
          iconPack: "feather",
          icon: "icon-alert-circle",
          position: "top-right",
          time: 4000
        });
      });
    },
    //Close call details popup
    closeCallDetailPopup(){
      this.callUpdateActive=false
      setTimeout(() => {
        this.isCallDetailsPopupActive = false
      }, 1000);
    }
  },
  computed: {
    showHideTranscriptionLabel: function () {
      if(this.transcriptionShow){
        return this.LabelConstant.buttonLabelHideTranscription
      }else{
        return this.LabelConstant.buttonLabelShowTranscription
      }
    },
    baseURL: function(){
      return process.env.VUE_APP_API_URL 
    },

    /**
		 * Showing Entries details
		 * isTableRecordEntriesDisplay()
		 * isSearch()
		 * showingDataFrom()
		 * showingDataTo()
		 * totalSearchedData()
     * showingSearchedDataTo()
		 *  */
    isTableRecordEntriesDisplay(){
			if(this.callActivityData.length > 0){
				if(this.$refs.tableData.datax.length > 0){
					return true
				}else{
					return false
				}
			}else{
				return false
			}
		},
    isSearch: function () {
      if(this.callActivityData.length > 0){
        if(this.$refs.tableData.searchx == null || this.$refs.tableData.searchx == ""){
            return false
        }else{
            return true
        }
      }
    },
    showingDataFrom: function () {
      if(this.callActivityData.length > 0){
        return ((this.$refs.tableData.currentx - 1) * this.perPage) + 1 
      }
    },
    showingDataTo: function () {
      if(this.callActivityData.length > 0){
        let totalPages = this.$refs.tableData.getTotalPages
        if(totalPages == this.$refs.tableData.currentx){
            return ((this.$refs.tableData.currentx - 1) * this.perPage) + this.$refs.tableData.datax.length
        }else{
            return ((this.$refs.tableData.currentx) * this.perPage)
        }
      }
    },
    totalSearchedData: function () {
      if(this.callActivityData.length > 0){
        return this.$refs.tableData.queriedResults.length
      }
    },
    showingSearchedDataTo: function () {
      if(this.callActivityData.length > 0){
        let totalPages = this.$refs.tableData.getTotalPagesSearch
        if(totalPages == this.$refs.tableData.currentx){
            return ((this.$refs.tableData.currentx - 1) * this.perPage) + this.$refs.tableData.datax.length
        }else{
            return ((this.$refs.tableData.currentx) * this.perPage)
        }
      }
    }
  }
};
</script>
<!-- Remaning task for Call Dashboard (Asked to client)
  - APIs remaining to integrate. For below APIs ask for usage.
    /ws/CallTrackMainDashboard/GetProvisionedPhoneNumberCountForFreeTrial
-->
